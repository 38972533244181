<template>
	<div class="w-full mt-6">
		<div class="w-full">
			<div class="flex items-center mb-6">
				<span class="mr-6 text-lg icon-file-text"></span>
				<h4 class="text-base font-semibold text-gray-dark">PROPOSAL</h4>
			</div>

			<div class="w-full">
				<div v-for="(n, i) in qualifications"	:key="i" >
					<div v-if="showVendorProposal(i) && notPrequalification(i)" class="mb-4 border border-gray-lightest rounded-xl">
						<div class="flex flex-wrap w-full pl-4 py-3.5">
							<div class="flex-auto pr-4 mb-3" :class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'">
								<div class="flex flex-wrap">
									<div class="flex items-center mb-1 mr-1">
										<h6 class="cursor-pointer text-tertiary hover:text-tertiary-dark" @click="openVendorDetail(n.vendorId, n.isNewVendor)">Vendor #{{ n?.sequence }}</h6>
										<p class="icon-chevron-right text-tertiary"></p>
									</div>
									<div v-if="n.isNewVendor"
										class="flex px-4 py-1 mb-1 mr-1 text-xs rounded-lg bg-success-lightest text-success">
										<h6>New Vendor</h6>
									</div>
								</div>
								<div>
									<h5 class="font-semibold">{{ n?.vendorName }}</h5>
									<p class="text-xs break-words text-gray-dark">{{ n?.vendorEmail }}</p>
									<div class="flex flex-wrap items-center mr-3">
										<p class="mr-3 text-sm font-medium text-gray">Required Documents:</p>
										<p class="text-sm text-success">{{n?.isCompletedRequiredDocuments? 'Completed' : ''}}</p>
									</div>
								</div>
							</div>
							<div class="flex flex-auto" :class="projectDetailContainerWidth > 800? 'w-2/4': 'w-full pr-4'">
								<div class="flex w-full" :class="` ${projectDetailContainerWidth > 800 && n?.localPartners?.length === 0? 'border-l border-gray-lightest' : 'mb-4'}`">
									<div v-if="!statusVendorRejected(i) && (n?.localPartners?.length === 0 || allLocalPartnerEliminated(i) || everyLocalPartnerCandidate(n))" class="flex flex-col justify-center w-full" :class="projectDetailContainerWidth > 800? 'px-4': ''">
										<div class="flex flex-col justify-center mb-4">
											<label class="text-sm text-gray">Proposal Technical: <span class="text-error">*</span></label>
											<div class="flex items-center justify-between w-full">
												<div v-if="!n?.proposalFileAddress" class="w-full my-1">
													<div class="flex items-center justify-between w-full pl-4 border rounded-lg h-14"
														:class="`${n.errorFileTechnical.value? 'border-error' : 'border-primary'}`">
														<input type="file"
															:id="`upload-file-proposal-technical${i}`"
															:ref="`file${i}`"
															hidden
															@change="chooseFile($event, i, null, `file${i}`)" 
															v-if="!proposalInformation?.isCanceled && !sppProcess"/>
														<div class="w-full truncate">
															<span class="text-sm truncate text-gray">{{ n?.proposalFileName || '' }}</span>
														</div>
														<label v-if="!proposalInformation?.isCanceled && !sppProcess" :for="`upload-file-proposal-technical${i}`"
															class="flex items-center justify-center p-2 text-white truncate rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
															Pilih File
														</label>
														<label v-else :for="`upload-file-proposal-technical${i}`"
															class="flex items-center justify-center p-2 text-white truncate rounded-r-lg cursor-not-allowed bg-primary w-28 h-14">
															Pilih File
														</label>
													</div>
													<div>
														<p class="mt-1 text-xs text-tertiary"
															:class="{ 'text-error': n.errorFileTechnical.value  }">
															Supported file: .pdf, max file size: 10 MB
														</p>
													</div>
												</div>
												<div v-if="(n.proposalFileAddress) " class="w-full my-1">
													<div class="flex items-center justify-between w-full border rounded-lg border-primary">
														<div class="p-4 truncate">
															<p class="truncate text-gray">{{n?.proposalFileName}}</p>
														</div>
														<div class="p-4 cursor-pointer" @click="viewAttachment(n.proposalFileName, n.proposalFileDocumentNo)">
															<p class="text-2xl icon-eye text-primary hover:text-primary-dark"></p>
														</div>
													</div>
												</div>
											</div>
											<div class="flex justify-end" v-if="n.proposalFileAddress && hideChangeFile(i) && !sppProcess">
												<input
													type="file"
													:ref="`file${i}`"
													:id="`upload-file-change-proposal-technical${i}`"
													hidden
													@change="chooseFile($event, i, null, `file${i}`)"
													v-if="!proposalInformation?.isCanceled"
												/>
												<label :for="`upload-file-change-proposal-technical${i}`" 
													class="pr-4 text-sm font-bold underline decoration-1"
													:class="`${n.errorFileTechnical.value? 'text-error': 'text-secondary hover:text-secondary-dark'} ${proposalInformation?.isCanceled? 'cursor-not-allowed' : 'cursor-pointer'}`">Change file</label>
											</div>
										</div>
										<div class="flex flex-col justify-center">
											<label class="text-sm text-gray">Proposal Price: <span class="text-error">*</span></label>
											<div class="flex items-center justify-between w-full">
												<div v-if="!n?.proposalPriceFileAddress" class="w-full my-1">
													<div class="flex items-center justify-between w-full pl-4 border rounded-lg h-14"
														:class="`${n.errorFilePrice.value ? 'border-error' : 'border-primary'}`">
														<input type="file"
															:id="`upload-file-proposal-price${i}`"
															:ref="`file-price${i}`"
															hidden
															@change="chooseFilePrice($event, i, null, `file-price${i}`)" 
															v-if="!proposalInformation?.isCanceled && !sppProcess"/>
														<div class="w-full truncate">
															<span class="text-sm truncate text-gray">{{ n?.proposalPriceFileName || '' }}</span>
														</div>
														<label v-if="!proposalInformation?.isCanceled && !sppProcess" :for="`upload-file-proposal-price${i}`"
															class="flex items-center justify-center p-2 text-white truncate rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
															Pilih File
														</label>
														<label v-else :for="`upload-file-proposal-technical${i}`"
															class="flex items-center justify-center p-2 text-white truncate rounded-r-lg cursor-not-allowed bg-primary w-28 h-14">
															Pilih File
														</label>
													</div>
													<div>
														<p class="mt-1 text-xs text-tertiary"
															:class="{ 'text-error': n.errorFilePrice.value }">
															Supported file: .pdf, max file size: 10 MB
														</p>
													</div>
												</div>
												<div v-if="n.proposalPriceFileAddress" class="w-full my-1">
													<div class="flex items-center justify-between w-full border rounded-lg border-primary">
														<div class="p-4 truncate">
															<p class="truncate text-gray">{{n?.proposalPriceFileName}}</p>
														</div>
														<div class="p-4 cursor-pointer" @click="viewAttachment(n.proposalPriceFileName, n.proposalPriceFileDocumentNo)">
															<p class="text-2xl icon-eye text-primary hover:text-primary-dark"></p>
														</div>
													</div>
												</div>
											</div>
											<div class="flex justify-end" v-if="n.proposalPriceFileAddress && hideChangeFile(i) && !sppProcess">
												<input
													type="file"
													:ref="`file-price${i}`"
													:id="`upload-file-change-proposal-price${i}`"
													hidden
													@change="chooseFilePrice($event, i, null, `file-price${i}`)"
													v-if="!proposalInformation?.isCanceled"
												/>
												<label :for="`upload-file-change-proposal-price${i}`" 
													class="pr-4 text-sm font-bold underline decoration-1"
													:class="`${ n.errorFilePrice.value? 'text-error': 'text-secondary hover:text-secondary-dark'} ${proposalInformation?.isCanceled? 'cursor-not-allowed': 'cursor-pointer'}`">Change file</label>
											</div>
										</div>
									</div>
									<div v-if="statusVendorRejected(i) && (n?.localPartners?.length === 0 || allLocalPartnerEliminated(i))" class="w-full" :class="projectDetailContainerWidth > 800? 'px-4': ''">
										<div class="flex w-full">
											<div class="w-full mt-1 mb-6">
												<div class="w-full h-full">
													<div class="">
														<p class="text-sm font-medium text-gray">Reject Note:</p>
													</div>
													<div class="pt-2">
														<p v-if="n?.rejectNotes?.length > 100" class="text-sm break-words whitespace-pre-wrap text-gray">{{n?.rejectNotes.slice(0,100)}}... <span class="cursor-pointer text-secondary" @click="readMore(n?.rejectNotes)">Read More</span></p>
														<p v-else class="text-sm break-words whitespace-pre-wrap text-gray">{{n?.rejectNotes}}</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div 
								class="flex flex-wrap items-center justify-center flex-auto px-4"
								:class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'"
								>
								<ProposalRevisionITP v-if="menuQualificationActionAskRevision?.canUpdate && statusVendorRejected(i) && (n.localPartners?.length === 0 || allLocalPartnerEliminated(i)) && !sppProcess" 
									class="flex items-center justify-center w-full"
									:qualification="n" :index="i" />
								<EliminateDocuments v-if="showEliminate(n, i) && (allLocalPartnerNotUploaded(n) || allLocalPartnerEliminated(i)) && !sppProcess"
									class="flex items-center justify-center w-full mb-3 mr-3" 
									:qualification="n"	:index="i" :rfpId="rfpId"/>
								<div v-if="proposalVendorApproved(i) && (n.localPartners === 0 || allLocalPartnerEliminated(i))" class="flex items-center">
									<p class="text-sm font-medium text-success">Approved</p>
								</div>
							</div>
						</div>
						<!-- split -->
						<div class="pb-4 pl-4" v-if="n.localPartners?.length > 0">
							<div v-for="(local, j) in n.localPartners" :key="j" >
								<div v-if="local.statusVendor !== PRA_QUALIFICATION_ELIMINATED && local.statusVendor !== QUALIFICATION_ELIMINATED" class="border-l border-gray-light">
									<div v-if="statusLocalPartnerNotPrequalification(local)" class="flex flex-wrap w-full py-3.5">
										<div class="flex-auto px-4 mb-3" :class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'">
											<div class="flex flex-wrap">
												<div class="flex items-center mb-1 mr-1">
													<h6 class="cursor-pointer text-tertiary hover:text-tertiary-dark" @click="openVendorDetail(local.vendorId, local.isNewVendor)">Local Partner #{{ j + 1 }}</h6>
													<p class="icon-chevron-right text-tertiary"></p>
												</div>
												<div v-if="local.isNewVendor"
													class="flex px-4 py-1 mb-1 mr-1 text-xs rounded-lg bg-success-lightest text-success">
													<h6>New Vendor</h6>
												</div>
											</div>
											<div>
												<h5 class="font-semibold">{{ local?.vendorName }}</h5>
												<p class="text-xs break-words text-gray-dark">{{ local?.vendorEmail }}</p>
												<div class="flex flex-wrap items-center mr-3">
													<p class="mr-3 text-sm font-medium text-gray">Required Documents:</p>
													<p class="text-sm text-success">{{local?.isCompletedRequiredDocuments? 'Completed' : ''}}</p>
												</div>
											</div>
										</div>
										<div class="flex flex-auto" :class="projectDetailContainerWidth > 800? 'w-2/4 border-l border-gray-lightest': 'w-full mb-4'">
											<div class="w-full" :class="projectDetailContainerWidth > 800? 'px-4': 'pr-4'" >
												<div v-if="!statusLocalPartnerRejected(i,j) && !statusLocalPartnerEliminated(i,j)" class="flex flex-col justify-center w-full mb-4" :class="projectDetailContainerWidth > 800? '': 'pl-4'">
													<label class="text-sm text-gray">Proposal Technical: <span class="text-error">*</span></label>
													<div class="flex items-center justify-between w-full">
														<div v-if="!local?.proposalFileAddress" class="w-full my-1">
															<div class="flex items-center justify-between w-full pl-4 border rounded-lg h-14"
																:class="`${local.errorFileTechnical.value ? 'border-error' : 'border-primary'}`">
																<input type="file"
																	:id="`upload-file-proposal-technical-local-partner${i}${j}`"
																	:ref="`file-technical-${i}-${j}`"
																	hidden
																	@change="chooseFile($event, i, j, `file-technical-${i}-${j}`)" 
																	v-if="!proposalInformation?.isCanceled && !sppProcess"/>
																<div class="w-full truncate">
																	<span class="text-sm truncate text-gray">{{ local?.proposalFileName || '' }}</span>
																</div>
																<label v-if="!proposalInformation?.isCanceled && !sppProcess" :for="`upload-file-proposal-technical-local-partner${i}${j}`"
																	class="flex items-center justify-center p-2 text-white truncate rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
																	Pilih File
																</label>
																<label v-else :for="`upload-file-proposal-technical-local-partner${i}${j}`"
																	class="flex items-center justify-center p-2 text-white truncate rounded-r-lg cursor-not-allowed bg-primary w-28 h-14">
																	Pilih File
																</label>
															</div>
															<div>
																<p class="mt-1 text-xs text-tertiary"
																	:class="{ 'text-error': local.errorFileTechnical.value }">
																	Supported file: .pdf, max file size: 10 MB
																</p>
															</div>
														</div>
														<div v-if="(local.proposalFileAddress) " class="w-full my-1">
															<div class="flex items-center justify-between w-full border rounded-lg border-primary">
																<div class="p-4 truncate">
																	<p class="truncate text-gray">{{local?.proposalFileName}}</p>
																</div>
																<div class="p-4 cursor-pointer" @click="viewAttachment(local.proposalFileName, local.proposalFileDocumentNo)">
																	<p class="text-2xl icon-eye text-primary hover:text-primary-dark"></p>
																</div>
															</div>
														</div>
													</div>
													<div class="flex justify-end" v-if="local.proposalFileAddress && hideChangeFileLocalPartner(i,j) && !sppProcess">
														<input
															type="file"
															:ref="`file-technical-${i}-${j}`"
															:id="`upload-file-change-proposal-technical-local-partner${i}${j}`"
															hidden
															@change="chooseFile($event, i,j, `file-technical-${i}-${j}`)"
															v-if="!proposalInformation?.isCanceled"
														/>
														<label :for="`upload-file-change-proposal-technical-local-partner${i}${j}`" 
															class="pr-4 text-sm font-bold underline decoration-1"
															:class="`${ local.errorFileTechnical.value ? 'text-error': 'text-secondary hover:text-secondary-dark'} ${proposalInformation?.isCanceled? 'cursor-not-allowed': 'cursor-pointer'}`">Change file</label>
													</div>
												</div>
												<div v-if="!statusLocalPartnerRejected(i,j) && !statusLocalPartnerEliminated(i, j)" class="flex flex-col justify-center w-full" :class="projectDetailContainerWidth > 800? '': 'pl-4'">
													<label class="text-sm text-gray">Proposal Price: <span class="text-error">*</span></label>
													<div class="flex items-center justify-between w-full">
														<div v-if="!local?.proposalPriceFileAddress" class="w-full my-1">
															<div class="flex items-center justify-between w-full pl-4 border rounded-lg h-14"
																:class="`${local.errorFilePrice.value ? 'border-error' : 'border-primary'}`">
																<input type="file"
																	:id="`upload-file-proposal-price-local-partner${i}`"
																	:ref="`file-price-${i}-${j}`"
																	hidden
																	@change="chooseFilePrice($event, i, j, `file-price-${i}-${j}`)" 
																	v-if="!proposalInformation?.isCanceled && !sppProcess"/>
																<div class="w-full truncate">
																	<span class="text-sm truncate text-gray">{{ local?.proposalPriceFileName || '' }}</span>
																</div>
																<label v-if="!proposalInformation?.isCanceled && !sppProcess" :for="`upload-file-proposal-price-local-partner${i}`"
																	class="flex items-center justify-center p-2 text-white truncate rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
																	Pilih File
																</label>
																<label v-else :for="`upload-file-proposal-technical-local-partner${i}${j}`"
																	class="flex items-center justify-center p-2 text-white truncate rounded-r-lg cursor-not-allowed bg-primary w-28 h-14">
																	Pilih File
																</label>
															</div>
															<div>
																<p class="mt-1 text-xs text-tertiary"
																	:class="{ 'text-error': local.errorFilePrice.value }">
																	Supported file: .pdf, max file size: 10 MB
																</p>
															</div>
														</div>
														<div v-if="local.proposalPriceFileAddress" class="w-full my-1">
															<div class="flex items-center justify-between w-full border rounded-lg border-primary">
																<div class="p-4 truncate">
																	<p class="truncate text-gray">{{local?.proposalPriceFileName}}</p>
																</div>
																<div class="p-4 cursor-pointer" @click="viewAttachment(local.proposalPriceFileName, local.proposalPriceFileDocumentNo)">
																	<p class="text-2xl icon-eye text-primary hover:text-primary-dark"></p>
																</div>
															</div>
														</div>
													</div>
													<div class="flex justify-end" v-if="local.proposalPriceFileAddress && hideChangeFileLocalPartner(i,j) && !sppProcess">
														<input
															type="file"
															:ref="`file-price-${i}-${j}`"
															:id="`upload-file-change-proposal-price-local-partner${i}${j}`"
															hidden
															@change="chooseFilePrice($event, i, j, `file-price-${i}-${j}`)"
															v-if="!proposalInformation?.isCanceled"
														/>
														<label :for="`upload-file-change-proposal-price-local-partner${i}${j}`" 
															class="pr-4 text-sm font-bold underline decoration-1"
															:class="`${ local.errorFilePrice.value ? 'text-error': 'text-secondary hover:text-secondary-dark'} ${proposalInformation?.isCanceled? 'cursor-not-allowed': 'cursor-pointer'}`">Change file</label>
													</div>
												</div>
												<div v-if="statusLocalPartnerRejected(i, j) && n?.localPartners?.length > 0" class="w-full" :class="projectDetailContainerWidth > 800? 'px-4': 'pl-4'">
													<div class="flex w-full">
														<div class="w-full mt-1 mb-6">
															<div class="w-full h-full">
																<div class="">
																	<p class="text-sm font-medium text-gray">Reject Note:</p>
																</div>
																<div class="pt-2">
																	<p v-if="local?.rejectNotes?.length > 100" class="text-sm break-words whitespace-pre-wrap text-gray">{{local?.rejectNotes.slice(0,100)}}... <span class="cursor-pointer text-secondary" @click="readMore(local?.rejectNotes)">Read More</span></p>
																	<p v-else class="text-sm break-words whitespace-pre-wrap text-gray">{{local?.rejectNotes}}</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div v-if="statusLocalPartnerEliminated(i, j) && n?.localPartners?.length > 0" class="w-full" :class="projectDetailContainerWidth > 800? 'px-4': 'pl-4'">
													<div class="flex w-full">
														<div class="w-full mt-1 mb-6">
															<div class="w-full h-full">
																<div class="">
																	<p class="text-sm font-medium text-gray">Eliminate Reason:</p>
																</div>
																<div class="pt-2">
																	<p v-if="local?.disqualifiedReason?.length > 100" class="text-sm break-words whitespace-pre-wrap text-gray">{{local?.disqualifiedReason.slice(0,100)}}... asdf <span class="cursor-pointer text-secondary" @click="readMore(local?.disqualifiedReason)">Read More</span></p>
																	<p v-else class="text-sm break-words whitespace-pre-wrap text-gray">{{local?.disqualifiedReason}}</p>
																</div>
															</div>
														</div>
													</div>
												</div>
												
											</div>
										</div>
										<div 
											class="flex flex-wrap items-center justify-center flex-auto px-4"
											:class="projectDetailContainerWidth > 800? 'w-1/4': 'w-full'"
											>
											<ProposalRevisionITP v-if="menuQualificationActionAskRevision?.canUpdate && statusLocalPartnerRejected(i,j) && !sppProcess" 
												class="flex items-center justify-center w-full"
												:qualification="local" :index="j" />
											<EliminateDocuments v-if="showEliminate(local) && !sppProcess" 
												class="flex items-center justify-center w-full mb-3 mr-3" 
												:qualification="local"	:index="j" :rfpId="rfpId"/>
											<div v-if="proposalLocalPartnerApproved(i,j)" class="flex items-center">
												<p class="text-sm font-medium text-success">Approved</p>
											</div>
											<div v-if="statusLocalPartnerEliminated(i, j) && n?.localPartners?.length > 0" class="flex flex-row flex-auto"
												:class="{'w-1/4 flex-col justify-center items-center px-3':projectDetailContainerWidth > 800}">
												<div v-if="local?.disqualifiedFileAddress" class="flex" :class="{'justify-center items-center':projectDetailContainerWidth > 800}" >
													<span class="mr-2 icon-file"></span>
													<p class="text-sm underline cursor-pointer text-secondary" @click="viewAttachment(local?.disqualifiedFileName, local?.disqualifiedFileDocumentNo)">View Attachment</p>
												</div>
											</div>
										</div>
									</div>
									<div v-if="j < lengthLocalPartnerNotPraqualification(n)" class="border-t-2 border-dashed border-gray-lightest"></div>
								</div>
							</div>
						</div>

					</div>
				</div>
				<GistaModal 
					:value="modalVendorDetail"
					width="2/3"
					scrollable
					@input="closeModalVendorDetail"
					customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
					<VendorDetail @closeModal="closeModalVendorDetail" :vendor="modalVendorProps" :projectId="proposalInformation.projectId" ></VendorDetail>
				</GistaModal>
			</div>
			<ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="rejectNotesModal" title="Reject Notes"/>
		</div>
	</div>
</template>
<script>
import ProposalRevisionITP from '@/core/components/modal/ProposalRevisionITP.vue';
import EliminateDocuments from '@/core/components/modal/EliminateDocuments.vue';
import VendorDetail from '@/core/components/vendors/VendorDetail'
import { PROPOSAL_REJECTED, PROPOSAL_APPROVED, APPROVAL_ELIMINATED, PROPOSAL_UPLOADED, QUALIFICATION_ELIMINATED, PRA_QUALIFICATION_ELIMINATED, 
	RFP_SENT, CANDIDATE, PROPOSAL_WAITING_CHANGES, PROPOSAL_SENT, SCORING_TECH_APPROVED, SCORING_DECISION_ELIMINATED, SCORING_DECISION_NEGOTIATION, SCORING_DECISION_APPROVAL, APPROVAL_SELECTED } from '@/core/constant/qualification.js'
import { DOCUMENT_NAME_PROPOSAL, DOCUMENT_NAME_PROPOSAL_PRICE } from '@/core/constant/documentName.js'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'


export default {
	name: "SendProposalITP",
	data() {
		return {
			error: [],
			rejectNotesModal: '',
			readMoreShow: false,
			modalVendorDetail: false,
			modalVendorProps: {
				vendorId: '',
				isNewVendor: true,
			},
			timeout: null,
			PRA_QUALIFICATION_ELIMINATED,
			QUALIFICATION_ELIMINATED
		}
	},
	computed: {
		qualifications: {
			get() {
				return this.$store.state.qualification.qualifications
			},
			set(value) {
				this.$store.commit('qualification/SET_QUALIFICATIONS', value)
			}
		},
		rfpId() {
			return this.$store.state.qualification.rfpId
		},
		menu() {
			return this.$store.state.dashboard.menu
		},
		menuQualificationActionAskRevision() {
			return this.$store.getters['dashboard/menuQualificationActionAskRevision']
		},
		proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
		projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
		proposalVendorShow() {
			const statusVendor = [PROPOSAL_REJECTED, PROPOSAL_APPROVED, PROPOSAL_UPLOADED, RFP_SENT, PROPOSAL_SENT]
			return this.qualifications.some(e => statusVendor.includes(e.statusVendor))
		},
		sppProcess() {
			return this.$store.state.spp.sppProcess
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
	},
	methods: {
		openVendorDetail(vendorId, isNewVendor) {
			if(this.sppProcess) return
			this.modalVendorProps = { vendorId: vendorId, isNewVendor: isNewVendor }
			this.modalVendorDetail = !this.modalVendorDetail
		},
		closeModalVendorDetail() {
			this.modalVendorDetail = !this.modalVendorDetail
			this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
		},
		async chooseFile(e, i, j, fileRefInput) {
			//show loading
			this.modal.modalLoading = true;
			const file = e.target.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
			if (checkFileValidation) {
				this.modal.modalLoading = false
				if (j !== null) {
					this.qualifications[i].localPartners[j].errorFileTechnical = { value: true, message: '' }
					setTimeout(() => {
						console.log(i, j, 'index', this.qualifications[i].localPartners[j].errorFileTechnical);
						this.qualifications[i].localPartners[j].errorFileTechnical = { value: false, message: '' }
					}, 5000)
				} else {
					this.qualifications[i].errorFileTechnical = { value: true, message: '' }
					setTimeout(() => {
						this.qualifications[i].errorFileTechnical = { value: false, message: '' }
					}, 5000)
				}
			} else {
				this.uploadFile(e.target.files[0], DOCUMENT_NAME_PROPOSAL, i, j)
			}
			this.$refs[fileRefInput][0].value = ''
		},
		async chooseFilePrice(e, i, j, fileRefInput) {
			//show loading
			this.modal.modalLoading = true;
			const file = e.target.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
			if (checkFileValidation) {
				this.modal.modalLoading = false
				if (j !== null) {
					this.qualifications[i].localPartners[j].errorFilePrice = { value: true, message: '' }
					setTimeout(() => {
						this.qualifications[i].localPartners[j].errorFilePrice = { value: false, message: '' }
					}, 5000)
				} else {
					this.qualifications[i].errorFilePrice = { value: true, message: '' }
					setTimeout(() => {
						this.qualifications[i].errorFilePrice = { value: false, message: '' }
					}, 5000)
				}
			} else {
				this.uploadFile(e.target.files[0], DOCUMENT_NAME_PROPOSAL_PRICE, i, j)
			}
			this.$refs[fileRefInput][0].value = ''
		},
		async uploadFile(file, docName, i, j) {
			try {
				//hash file checksum md5
				const encrypted = await this.$store.dispatch('file/hashMD5',file)
				const formData = new FormData()
				formData.append('file', file)
				formData.append('documentName', docName)
				formData.append('projectId', this.proposalInformation.projectId)
				//checksum encrypted md5
				formData.append('checksum', encrypted)
				if (j !== null) {
					formData.append('vendorId', this.qualifications[i]?.localPartners[j]?.vendorId)
					formData.append('isNewVendor', this.qualifications[i]?.localPartners[j]?.isNewVendor)
				} else {
					formData.append('vendorId', this.qualifications[i]?.vendorId)
					formData.append('isNewVendor', this.qualifications[i]?.isNewVendor)
				}
				const response = await this.$store.dispatch('vendor/postVendorDocumentsUploadProject', formData)
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false;
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				//loading out when error
				this.modal.modalLoading = false;
				//show error upload file proposal
				this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }				
			}
			await this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
		},
		statusVendorRejected(i) {
			return this.qualifications[i].statusVendor === PROPOSAL_REJECTED
		},
		anyLocalVendorRejected(i) {
			return this.qualifications[i].localPartners.some(e => e.statusVendor === PROPOSAL_REJECTED)
		},
		statusLocalPartnerRejected(i,j) {
			return this.qualifications[i].localPartners[j]?.statusVendor === PROPOSAL_REJECTED
		},
		statusLocalPartnerEliminated(i, j) {
			return this.qualifications[i].localPartners[j]?.statusVendor === QUALIFICATION_ELIMINATED || this.qualifications[i].localPartners[j]?.statusVendor === PRA_QUALIFICATION_ELIMINATED
		},
		showEliminate(vendor, i) {
			const statusVendor = [CANDIDATE, RFP_SENT, PROPOSAL_WAITING_CHANGES]
			if(i !== undefined && this.qualifications[i]?.localPartners?.length > 0) {
				const rfpSent = this.qualifications[i]?.localPartners?.some(e => [RFP_SENT, PROPOSAL_WAITING_CHANGES].includes(e.statusVendor))
				if([SCORING_DECISION_NEGOTIATION, SCORING_DECISION_APPROVAL].includes(vendor.statusVendor) && rfpSent) return true
			}
			return statusVendor.includes(vendor.statusVendor)
		},
		allLocalPartnerNotUploaded(vendor) {
			const statusVendor = [CANDIDATE, RFP_SENT, PROPOSAL_WAITING_CHANGES]
			return vendor.localPartners.length === 0 || vendor.localPartners.some(e => statusVendor.includes(e.statusVendor))
		},
		showVendorProposal(i) {
			return this.qualifications[i].statusVendor !== QUALIFICATION_ELIMINATED && this.qualifications[i].statusVendor !== PRA_QUALIFICATION_ELIMINATED
		},
		proposalVendorApproved(i) {
			return this.qualifications[i].isProposalApproved 
		},
		proposalLocalPartnerApproved(i,j) {
			return this.qualifications[i].localPartners[j]?.isProposalApproved 
		},
		statusWaitingChanges(i) {
			return this.qualifications[i].statusVendor === PROPOSAL_WAITING_CHANGES
		},
		statusWaitingChangesLocalPartner(i,j) {
			return this.qualifications[i].localPartners[j]?.statusVendor === PROPOSAL_WAITING_CHANGES
		},
		statusFileUploaded(i) {
			return this.qualifications[i].statusVendor === PROPOSAL_UPLOADED
		},
		notPrequalification(i) {
			return this.qualifications[i].statusVendor !== CANDIDATE
		},
		allLocalPartnerEliminated(i) {
			return this.qualifications[i].localPartners?.every(e => e.statusVendor === QUALIFICATION_ELIMINATED || e.statusVendor === PRA_QUALIFICATION_ELIMINATED)
		},
		everyLocalPartnerCandidate(vendor) {
			return vendor.localPartners?.every(e => e.statusVendor === CANDIDATE)
		},
		statusLocalPartnerNotPrequalification(local) {
			return local.statusVendor !== CANDIDATE
		},
		lengthLocalPartnerNotPraqualification(n) {
			return n.localPartners?.filter(e => e.statusVendor !== CANDIDATE).length-1
		},
		async viewAttachment(filename, documentNo) {
			this.$store.dispatch('file/viewAttachment', { filename, documentNo })
		},
		closeModal() {
			this.readMoreShow = false
		},
		readMore(rejectNotes) {
			this.rejectNotesModal = rejectNotes
			this.readMoreShow = true
		},
		hideChangeFile(i) {
			const statusVendor = [PROPOSAL_SENT, PROPOSAL_APPROVED, SCORING_DECISION_ELIMINATED, SCORING_DECISION_NEGOTIATION, SCORING_DECISION_APPROVAL, SCORING_TECH_APPROVED, APPROVAL_SELECTED, APPROVAL_ELIMINATED ]
			const statusVendorProposal = statusVendor.includes(this.qualifications[i].statusVendor)
			return !statusVendorProposal
		},
		hideChangeFileLocalPartner(i, j) {
			const statusVendor = [PROPOSAL_SENT, PROPOSAL_APPROVED, SCORING_DECISION_ELIMINATED, SCORING_DECISION_NEGOTIATION, SCORING_DECISION_APPROVAL, SCORING_TECH_APPROVED, APPROVAL_SELECTED, APPROVAL_ELIMINATED ]
			const statusVendorProposal = statusVendor.includes(this.qualifications[i].localPartners[j].statusVendor)
			return !statusVendorProposal
		}
	},
	components: {
		ProposalRevisionITP,
		EliminateDocuments,
		VendorDetail,
		ModalReadMore
	}

}

</script>
