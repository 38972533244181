<template>
  <div>
    <div class="mb-3.5" v-for="n,i in dataProcurementApproval.attachmentList?.filter(e => !e.isDeleted)" :key="i">
        <div>
          <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
        </div>
        <div class="flex items-center justify-between border rounded-lg h-14 border-primary">
          <div class="text-base font-medium text-gray p-4 truncate">{{ n.fileName }}</div>
          <div class="p-4 flex items-center">
            <span v-if="(!isSubmitted || isRejected) && !readOnly" class="cursor-pointer text-2xl icon-trash-2 text-error hover: text-error-dark" @click="clickRemoveFileAttachment(n)"></span>
            <span v-else class="cursor-pointer text-2xl icon-eye text-primary hover:text-primary-dark" @click="openFile(n)" ></span>
          </div>
        </div>
    </div>
    <div v-if="(!isSubmitted || isRejected) && !readOnly && maxAttachment">
      <div class="flex flex-wrap items-center">
        <div class="flex">
          <span class="mr-2 icon-file-plus flex items-center text-secondary"></span>
          <input type="file"
            id="upload-file-attachment"
            :ref="approvalRef"
            hidden
            @change="addAttachment($event, approvalRef)" />
          <label for="upload-file-attachment"
            class="mr-4 text-base font-medium underline cursor-pointer text-secondary">
            Add
          </label>
        </div>
        <p class="mr-3.5" :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Max Attachment: 10 </p>
        <p :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Supported file: .pdf, max file size: 10 MB</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateProcurementApproval",
  props: ['readOnly'],
  data() {
    return {
      approvalRef: 'approval',
			errorFileType: false,
    }
  },
  computed: {
    maxAttachment() {
      return this.dataProcurementApproval.attachmentList?.filter(e => !e.isDeleted).length < 10 || this.dataProcurementApproval.attachmentList === null
    },
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    isSubmitted() {
      return this.dataProcurementApproval?.isSubmitted
    },
    logApproval() {
      return this.$store.state.procurementApproval.logApproval
    },
    isRejected() {
      return this.dataProcurementApproval?.isRejected
    },
    modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		}
  },
  methods: {
    async addAttachment(e, fileRef) {
      const file = e.target?.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
      if(checkFileValidation){
        this.errorFileType = true
      } else {
				try {
					this.modal.modalLoading = true
					this.errorFileType = false
					const encrypted = await this.$store.dispatch('file/hashMD5',file)
					const formData = new FormData()
					formData.append('file', file)
					formData.append('fileRef', fileRef)
					//checksum encrypted md5
					formData.append('checksum', encrypted)
					formData.append('projectType', 'RFP')
					const response = await this.$store.dispatch('file/uploadFile', formData)
					if (response?.status < 300 && response?.status >= 200) {
						console.log(response?.headers, 'response headers upload file');
						const data = response.data.result
						const fileAfterUpload = {
							fileName: data.fileName,
							filePath: data.filePath,
							documentNo: data.documentNo,
							fileRef: fileRef,
							isDeleted: false,
							id: null
						}
            this.dataProcurementApproval.attachmentList = [ ...this.dataProcurementApproval.attachmentList, fileAfterUpload ]
          }
					this.modal.modalLoading = false
        } catch (error) {
					this.modal.modalLoading = false
					this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
      }
      if (this.$refs[fileRef]) {
        this.$refs[fileRef].value = ''
      }
    },
    openFile(n) {
			const { fileName, documentNo } = n
      this.$store.dispatch('file/viewAttachment', { filename: fileName, documentNo })
    },
    async clickRemoveFileAttachment(n){
      console.log(n);
      this.modal.modalLoading = true
      if (n.id !== null) {
        this.dataProcurementApproval.attachmentList = this.dataProcurementApproval.attachmentList?.map(e => (e.filePath === n.filePath? ({ ...e, isDeleted: true }): e))
        this.modal.modalLoading = false
      } else {
        const response = await this.$store.dispatch('file/deleteFile', n.documentNo || n.fileName)
        if (response?.status < 300) {
          this.dataProcurementApproval.attachmentList = this.dataProcurementApproval.attachmentList?.filter(e => e.filePath !== n.filePath)
        }
        this.modal.modalLoading = false
      }
    },
  }
}
</script>