<template>
  <div class="w-full">
    <div class="relative flex flex-wrap items-center w-full gap-6 my-6">
      <span class="flex-1 text-2xl cursor-pointer icon-arrow-left text-gray" @click="clickBackArrow"></span>
      <h4 class="flex flex-wrap justify-center flex-1 flex-auto text-lg font-medium text-gray-darkest">Change Approvers</h4>
      <button @click="expandCollapseAll" class="flex justify-end flex-1 underline cursor-pointer text-tertiary hover:text-tertiary-dark" >{{messageCollapseAll}}</button>
    </div>
    <TemplateFormProcurementApproval :collapse="collapse" @changeCollapse="changeCollapse" :showRecommendation="true" />
    <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
      <div class="flex justify-end" :class="projectDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
        <div class="gap-6" :class="projectDetailContainerWidth > 640 ? 'grid grid-cols-2' : 'flex flex-col'">
          <ButtonGista type="secondary" color="primary" @click="clickPreview">Preview</ButtonGista>
          <SubmitChangeApprovals @click="submitChangeApprovers($event)" :isOpen="isOpenChangeApprovers" @clickToggle="clickChangeIsOpenChangeApprovers"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubmitChangeApprovals from '@/core/components/rfp/procurementApproval/SubmitChangeApprovals.vue'
import { MESSAGE_SUCCESS_APPROVAL_CHANGE_APPROVER, MESSAGE_SUCCESS_CANCEL_PROCUREMENT_APPROVAL } from '@/core/constant/successMessage'
import { PATH_ROOT } from '@/core/constant/routeName'
import TemplateFormProcurementApproval from '@/core/components/rfp/procurementApproval/TemplateFormProcurementApproval.vue'

export default {
  name: "CreateProcurementApproval",
  data() {
    return {
      messageCollapseAll: 'Collapse All',
      collapse: {
        information: false,
        negoResults: false,
        vendorCandidateScoring: false,
        projectApproval: false,
        costBudget: false,
        recommendation: true,
        checker: true,
        approver: true,
        attachments: false,
      },
      isOpenSubmit: false,
      isOpenChangeApprovers: false,
      isOpenCancel: false,
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    errorMaxLength: {
      get() {
        return this.$store.state.procurementApproval.errorMaxLength
      },
      set(val) {
        this.$store.commit('procurementApproval/SET_ERROR_MAX_LENGTH', val)
      }
    },
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
   isSubmitted() {
      return this.dataProcurementApproval.isSubmitted
    }
  },
  methods:{
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key]
      if (Object.values(this.collapse).every(el => el)) this.messageCollapseAll = 'Collapse All'
      else if (Object.values(this.collapse).every(el => !el)) this.messageCollapseAll = 'Extend All'
    },
    expandCollapseAll() {
      for (const key in this.collapse) {
        if (this.messageCollapseAll === 'Extend All') {
          this.collapse[key] = true
        } else {
          this.collapse[key] = false
        }
      }
      if (this.messageCollapseAll === 'Collapse All') this.messageCollapseAll = 'Extend All'
      else this.messageCollapseAll = 'Collapse All'
    },
    async submitChangeApprovers(reason) {
      this.modal.modalLoading = true
      const response = await this.$store.dispatch('procurementApproval/putChangeApprovers', { approvalId: this.dataProcurementApproval.id, reason })
      if (response?.status < 300) {
        this.$emit('refetchApprovalLog')
        this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_APPROVAL_CHANGE_APPROVER }
        this.isOpenChangeApprovers = !this.isOpenChangeApprovers
        this.$store.commit('procurementApproval/SET_MAIN_PAGE', '')
      }
      this.modal.modalLoading = false
    },
    async clickPreview() {
      this.modal.modalLoading = true
      await this.$store.dispatch('procurementApproval/postApprovalPreview', { isSubmit: false })
      this.modal.modalLoading = false
    },
    async clickChangeIsOpenChangeApprovers() {
      this.isOpenChangeApprovers = !this.isOpenChangeApprovers
    },
    async clickCancel() {
      const response = await this.$store.dispatch('procurementApproval/cancelProcurementApproval', { approvalId: this.dataProcurementApproval.id })
      if (response?.status) {
        const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			  this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
      }
    },
    async clickBackArrow() {
      this.$store.commit('procurementApproval/SET_MAIN_PAGE', '')
			this.modal.modalSuccessSaved = {value: true, title: MESSAGE_SUCCESS_CANCEL_PROCUREMENT_APPROVAL }
      await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
    }
  },
  async mounted() {
    const res = await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
    if (res?.status === 200) {
      if (this.dataProcurementApproval.id > 0) {
        this.$store.dispatch('procurementApproval/getApprovalLog', { approvalId: this.dataProcurementApproval.id })
      }
    }
  },
  components:{
    SubmitChangeApprovals,
    TemplateFormProcurementApproval
  }
}
</script>

